import React from 'react'
import { withTranslation } from 'react-i18next';

import {Footer, SignupDivider, HeroPlain, Feature, VideoSection, GetStarted, Video} from '../../components';
import {WebLayout} from '../../layouts';

const FeaturesPage = ({t}) => (
  <WebLayout
      link={[{
          rel: "alternate", href: "https://www.duuers.com/fi/features", hreflang: "fi"
      },{
          rel: 'alternate', href: 'https://www.duuers.com/en/features', hreflang: 'en'
      },{
          rel: 'alternate', href: 'https://www.duuers.com/en/features', hreflang: 'x-default'
      }]}
      alwaysSticky={true}
      title="Proposal software - Professional proposals in 60 seconds - Duuers"
      meta={[
        {name: 'description', content: 'Proposal software - We provide an online tool that helps small businesses create proposals. Quick and easy to use. Create a free account today!'},
        {name: 'keywords', content: ''},
      ]}
    >
    <HeroPlain>
      <h1 className={"font_second semibold font40 white"}>{t('features.header')}</h1>
      <p className="font20 padding_top20 opacity9 text white">
        {t('features.text')}
      </p>
      <Video src="https://www.youtube.com/embed/mKfd15DuqLE?autoplay=1"/>
    </HeroPlain>
    <GetStarted/>
    <Feature 
      name={t('component.features.editor.name')} 
      descriptions={[
      'Our tool makes tailored suggestions that improve your proposals',
      'Create templates for your most popular content, then use them to create proposals',
      'Drag and drop content around with ease'
      ]}
      srcSet="/images/features/editor-fi.png 1x, /images/features/editor-fi@2x.png 2x, /images/features/editor-fi@3x.png 3x" src="/images/features/editor-fi.png">
    </Feature>
    <Feature 
      bg="light_blue"
      reverse
      name={t('component.features.contentLibrary.name')} 
      descriptions={[
        'Create a library of your most used content - price tables, images, videos, customer references, etc',
        'If you already have this content, you can upload it straight into the tool',
        'Add any content from this library to your proposals at the click of a button!'

      ]}
      srcSet="/images/features/elements-fi.png 1x, /images/features/elements-fi@2x.png 2x, /images/features/elements-fi@3x.png 3x" src="/images/features/elements-fi.png">
    </Feature>
    <Feature 
      name={t('component.features.liveEdit.name')} 
      descriptions={[
        'Include a range of items in your proposal, and let customers choose what they want from the list',
        'Edit proposals you’ve already sent, if you want to update information or correct a mistake'
      ]}
      srcSet="/images/features/live-edit-fi_.png 1x, /images/features/live-edit-fi@2x_.png 2x, /images/features/live-edit-fi@3x_.png 3x" src="/images/features/live-edit-fi_.png">
    </Feature>
    <Feature 
      bg="light_blue"
      reverse
      name={t('component.features.chat.name')} 
      descriptions={[
        'Use Duuers to communicate with your customers, straight from the tool',
        'Customers can comment, ask questions and give feedback directly on the proposal',
      ]}
      srcSet="/images/features/chat-fi_.png 1x, /images/features/chat-fi@2x_.png 2x, /images/features/chat-fi@3x_.png 3x" src="/images/features/chat-fi_.png">
    </Feature>
    <Feature 
      name={t('component.features.followUp.name')} 
      descriptions={[
        'See when a customer opens, views or accepts your proposal',
        'Get instant notifications on any customer questions',
        'Get notifications for proposals that are soon expiring (and again once they’ve expired)',
      ]}
      srcSet="/images/features/follow-up-fi.png 1x, /images/features/follow-up-fi@2x.png 2x, /images/features/follow-up-fi@3x.png 3x" src="/images/features/follow-up-fi.png">
    </Feature>
    <Feature 
      bg="light_blue"
      reverse
      name={t('component.features.teamwork.name')} 
      descriptions={[
        'Multiple users for one account - admin and user accounts available',
        'See who is handling what customer or deal, and cover for colleagues when they’re away',
        'Organise proposals based on person, status and date, using the inbuilt search and tag features'
      ]}
      srcSet="/images/features/teamwork-fi.png 1x, /images/features/teamwork-fi@2x.png 2x, /images/features/teamwork-fi@3x.png 3x" src="/images/features/teamwork-fi.png">
    </Feature>
    <Feature 
      name={t('component.features.branding.name')} 
      descriptions={[
        'Add your logo, brand colours and team pictures',
        'Add enticing pictures of your products and services'
      ]}
      srcSet="/images/features/branding-fi_.png 1x, /images/features/branding-fi@2x_.png 2x, /images/features/branding-fi@3x_.png 3x" src="/images/features/branding-fi_.png">
    </Feature>
    <Feature 
      bg="light_blue"
      reverse
      name={t('component.features.video.name')} 
      descriptions={[
        'Help seal the deal with a video - maybe a short one introducing yourself, or an explainer video about what your company does',
        'Cut and paste any link (from YouTube, Vimeo, etc) and the video will automatically be added to your proposal, along with a preview',
      ]}
      srcSet="/images/features/video-fi_.png 1x, /images/features/video-fi@2x_.png 2x, /images/features/video-fi@3x_.png 3x" src="/images/features/video-fi_.png">
    </Feature>
    <VideoSection src="https://www.youtube.com/embed/NCMPwtNYm1Y?autoplay=1" poster="/images/video-poster.png"/>
    <SignupDivider/>
    <Footer/>
  </WebLayout>
)

export default withTranslation()(FeaturesPage)
